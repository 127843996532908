.cgm-shell-error-notification-flyout-container {
  .error-notification-dropdown {
    position: fixed;
    margin-top: 0;
    transition: none;

    .error-notification-item {
      background-color: #ffffff;

      .error-details-wrapper {
        display: flex;
        color: black;
        font-size: .75rem;
        font-weight: bold;
        padding: .5rem;

        .date-section {
          width: 20%;
        }

        .error-body {
          flex: 1;

          .error-body-details {
            font-weight: normal;
          }
        }

        .commands-section {
          width: 12%;
          cursor: pointer;

          .ico-20pt {
            color: #0074bc;
          }
        }
      }
    }
  }

  &.stick-to-the-icon {
    .error-notification-dropdown {
      position: absolute;
      left: -4rem;
    }
  }
}