cgm-shell-feature-shortcuts {
  .tab-content {
    min-height: 15rem;
    height: 15rem;
    max-height: 15rem;
    overflow-y: auto;
  }
}

.cgm-shell-feature-shortcuts-dialog {
  min-width: 24rem;
  width: 24rem;
  max-width: 24rem;

  &.ui-dialog {
    box-shadow: #282828A0 0 1px 0.25rem;
    border-radius: 0;
  }

  .cgm-tab-container {
    .tab-content {
      top: 0;
      margin-top: -1px;
    }

    li.tab {
      margin-bottom: 0;
    }
  }
}
